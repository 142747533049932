<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-xsmall-size-100">
      <md-card >
        <form novalidate @submit.prevent="validateUser">
          <md-card-header>
            <md-card-header-text>
              <div class="md-title" v-if="instance.id">Editando proveedor</div>
              <div class="md-title" v-if="!instance.id">Crear proveedor</div>
            </md-card-header-text>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to(`/providers/`)">
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
            </md-menu>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout-item">
                <md-field :class="getValidationClass('name')">
                  <label for="name">Nombre</label>
                  <md-input name="name"
                  id="name"
                  autocomplete="given-name" v-model="instance.name" :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.name.required">EL nombre es requerido</span>
                </md-field>
              </div>
              <div class="md-layout-item">
                <md-field>
                  <label for="contact_name">Nombre de contacto</label>
                  <md-input name="contact_name"
                  id="contact_name"
                  autocomplete="given-contact_name"
                  v-model="instance.contact_name" :disabled="sending" />
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('phone')">
                  <label for="phone">Teléfono</label>
                  <md-input name="phone" id="phone" type="number"
                  autocomplete="phone" v-model="instance.phone" :disabled="sending" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('nit')">
                  <label for="nit">NIT</label>
                  <md-input name="nit"
                  id="nit" v-model="instance.nit" type="text" :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.nit.required">El NIT es requerido</span>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <br>
                <md-switch v-model="instance.status">
                  <span v-if="instance.status">Activo</span>
                  <span v-if="!instance.status">Inactivo</span>
                </md-switch>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('address')">
                  <label for="address">Dirección</label>
                  <md-input type="text"
                  id="address" name="address" autocomplete="address"
                  v-model="instance.address" :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.address.required">La dirección es requerida</span>
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label for="email">Correo eléctronico</label>
                  <md-input type="email"
                  id="email" name="email" autocomplete="email"
                  v-model="instance.email" :disabled="sending" />
                </md-field>
              </div>
            </div>
          </md-card-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending" />
          <md-card-actions>
            <md-button
            type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
          </md-card-actions>
        </form>
        <br>
        <br>
      </md-card>
    </div>
    <md-snackbar
      :md-active.sync="userSaved">{{ lastUser }} ha sido actualizado!</md-snackbar>
  </div>
</template>
<script>
/* eslint-disable */
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'Form_customer',
  created(){
    if  (this.$route.params.id) {
      this.instance.id = this.$route.params.id
      this.retrieveInvoice();
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      logo_url: '',
      company: null,
      base: process.env.VUE_APP_BASE_URL,
      instance: {
        name: null,
        email: '',
        address: 'CIUDAD',
        phone: null,
        contact_name: '',
        nit: 'CF',
      },
      url: 'providers/',
      userSaved: false,
      sending: false,
      lastUser: null
    };
  },
  validations: {
    instance: {
      name: {
        required,
      },
      address: {
        required
      },
      nit: {
        required,
      }
    },
  },
  methods: {
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    set_logo(document_id) {
      let payload = {}
      payload.change_logo = 'True'
      payload.id = this.instance.id
      payload.url = document_id
      const self = this;
      this.retrieveRequest(this.url, payload).then((data) => {
        self.instance = data.data
        self.$swal.fire('Actualizado', 'Actualización exitosa.', 'success');
      }).catch((error) =>{
        this.handlerError(error)
      });
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      self.$swal.fire('Error!', result, 'error');
      return false;
    },
    list() {
      const payload = {};
      const self = this;
      this.sendRequest(payload, this.url).then((data) => {
        self.list_data = data.data;
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
    },
    validateUser () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saveOrUpdate()
      }
    },
    saveOrUpdate () {
      if (!this.instance.name) {
        this.$swal.fire('Error!', 'El nombre requerido.', 'error')
        return false
      }
      const payload = this.instance
      const self = this
      this.sending = true
      if (this.instance.id) {
        this.putRequest(payload).then(function (data) {
          self.lastUser = `${self.instance.name}`
          self.userSaved = true
          self.sending = false
          self.instance = data.data
          self.clearForm()
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      if (this.$store.state.company_obj){      
        if (this.$store.state.company_obj.filter_my_data_app_conta){
          payload.company = this.$store.state.company_obj.id
        }
      }
      this.postRequest(payload).then(function (data) {
        self.lastUser = `${self.instance.name}`
        self.userSaved = true
        self.sending = false
        self.clearForm()
        self.instance = data.data
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    async retrieveInvoice () {
      const self = this
      const payload = {
        id: this.instance.id,
        basic_serializer: 'True'
      }
      await this.retrieveRequest(this.url, payload).then(function (data) {
        self.instance = data.data
      }).catch(function (error) {
        self.handlerError(error)
      })
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequest (payload) {
      const result = await this.$http.post(this.url, payload)
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
</style>
